import { $severity } from '../constants/accessibility';
import { $auditRule } from '../constants/audit-rule';
import { $auditIssueFields } from '../constants/manual-audit-issues';
import { IAuditDbRule } from './audit-rule.db.interface';
import { ISuccessCriteria } from '../audits/definitions/success-criteria/success-criteria.interface';
import { $auditStandardSuccessCriteria } from '../constants/audit-standard-success-criteria';
import { AuditDbStandard, AuditStandards } from '../constants/audit-standard';
import { IRuleAuditHistoryLineItem } from './rule-audit-history.interface';
import { AuditFindingDisabilityAffected } from '../constants/audit-finding';
import { DesignRuleLibrary } from '../constants/design-rule';
import { RuleLibraryOrigin, RuleLibraryMediaType } from '../constants/rule-library';
import { ICustomAuditDbRule } from './custom-audit-rule.interface';

export enum EssentialVersion {
  'v10' = '1.0',
}

export enum DequeVersion {
  'v40' = '4.0',
}

export enum WaveVersion {
  v10 = '1.0',
}

export enum WcagVersion {
  'v20' = '2.0',
  'v21' = '2.1',
  'v22' = '2.2',
}

export enum En301549Version {
  v321 = '3.2.1',
}

export enum Section508Version {
  v2018 = '2018',
}

/**
 * @deprecated unused functionality
 */
export enum CssVersion {
  'v20' = '2.0',
  'v30' = '3.0',
}

/**
 * @deprecated unused functionality
 */
export enum HtmlVersion {
  'v5' = '5.0',
}

export enum WcagLevel {
  'A' = 'A',
  'AA' = 'AA',
  'AAA' = 'AAA',
  'CR' = 'CR',
}

export enum IssueCategory {
  adaptable = 'adaptable',
  aria = 'aria',
  audio = 'audio',
  captcha = 'captcha',
  embedded_objects = 'embedded_objects',
  forms = 'forms',
  headings = 'headings',
  images = 'images',
  links = 'links',
  operable = 'operable',
  perceivable = 'perceivable',
  robust = 'robust',
  tables = 'tables',
  uncategorized = 'uncategorized',
  understandable = 'understandable',
  videos = 'videos',
}

export interface IEssential {
  version: EssentialVersion;
}

/**
 * @deprecated unused functionality
 */
interface ICss {
  version: CssVersion;
}

/**
 * @deprecated unused functionality
 */
interface IHtml {
  version: HtmlVersion;
}

export interface IAuditStandard {
  description?: string;
  id: AuditStandards;
  url?: string;
  /**
   * @deprecated unused functionality
   */
  [AuditStandards.css]?: ICss;
  [AuditStandards.essential]?: IEssential;
  /**
   * @deprecated unused functionality
   */
  [AuditStandards.html]?: IHtml;
  [AuditStandards.wcag]?: ISuccessCriteria;
  [AuditStandards.wcagCr]?: ISuccessCriteria;
  [AuditStandards.en301549]?: ISuccessCriteria;
  [AuditStandards.section508]?: ISuccessCriteria;
  [AuditStandards.notApplicable]?: ISuccessCriteria;
}

export interface IAuditRule extends IAuditRuleBase {
  [$auditRule._id]: string;
  [$auditRule.weight]: number;
  [$auditRule.whatToDo]?: string;
  [$auditRule.tool]?: string;
  [$auditRule.reasonForImportance]?: string;
  [$auditRule.wcagCriteria]?: string[];
  [$auditRule.atCombination]?: string;
  [$auditRule.recommendation]?: string;
  [$auditRule.stepsToReproduce]?: string;
  [$auditRule.actualResult]?: string;
  [$auditRule.disabilitiesAffected]?: AuditFindingDisabilityAffected[];
  [$auditRule.functionalImpact]?: string;
  [$auditRule.mapsTo]?: string;
  [$auditRule.referencedRule]?: ISimpleAuditRule;
  [$auditRule.applicableElementsSelectors]?: string[];
}

export interface ISimpleAuditRule {
  [$auditRule._id]: string;
  [$auditRule.ruleId]: string;
  [$auditRule.tool]: string;
  [$auditRule.title]: string;
  [$auditRule.description]: string;
}

export interface IAuditRuleBase {
  [$auditRule.description]: string;
  [$auditRule.isSelectedForScanning]: boolean;
  [$auditRule.isAvailableForScanning]?: boolean;
  [$auditRule.ruleId]: string;
  [$auditRule.severity]: $severity;
  [$auditRule.severityNumber]?: number;
  [$auditRule.standards]: IAuditStandard[];
  [$auditRule.title]: string;
  [$auditRule.ruleLibrary]?: string;
  [$auditRule.category]?: string;
  [$auditRule.testType]?: string;
  [$auditRule.mediaType]?: string;
  [$auditRule.bestPracticeId]?: string;
  [$auditRule.testId]?: string;
  [$auditRule.testName]?: string;
  [$auditRule.testManualSteps]?: string;

  /**
   * @deprecated
   */
  [$auditRule.categories]?: IssueCategory[];

  /**
   * @deprecated
   */
  [$auditRule.wcagConformanceLevel]?: string[];
  [$auditRule.mapsTo]?: string;
  [$auditRule.compliantCodeExample]?: string;
  [$auditRule.nonCompliantCodeExample]?: string;
  [$auditRule.techniques]?: string[];
  [$auditRule.applicableElementsSelectors]?: string[];
}

export interface IAuditRuleQuery {
  [$auditIssueFields.successCriteria]?: string;
  [$auditIssueFields.conformanceLevelAndVersion]?: string;
  [$auditIssueFields.category]?: string;
  [$auditIssueFields.ruleId]?: string;
}

export interface IAuditRuleServerItemSuccessCriteria {
  [$auditStandardSuccessCriteria.identifier]: string;
  [$auditStandardSuccessCriteria.auditStandards]: AuditDbStandard[];
}

export interface IAuditRuleServerItem {
  [$auditRule._id]: string;
  [$auditRule.ruleId]: string;
  [$auditRule.title]: string;
  [$auditRule.description]: string;
  [$auditRule.successCriterias]: IAuditRuleServerItemSuccessCriteria[];
  [$auditRule.severity]: $severity;
  [$auditRule.enabled]?: boolean;
  [$auditRule.history]?: IRuleAuditHistoryLineItem[];
  [$auditRule.applicableElementsSelectors]?: string[];
}

export interface IAuditRuleServerResponse {
  auditRules: IAuditRuleServerItem[] | IAuditDbRule[] | ICustomAuditDbRule[];
  _total: number;
}

export interface IAuditRuleLibrary {
  _id: string | DesignRuleLibrary;
  name: string | DesignRuleLibrary;
  code: string | DesignRuleLibrary;
  origin?: RuleLibraryOrigin;
  mediaType?: RuleLibraryMediaType;
  categories: string[];
}

export interface ILibraryIdWithCategories {
  id: string | DesignRuleLibrary;
  categories: string[];
}

export interface IFindAuditRulesOptions {
  skip?: number;
  limit?: number;
  sortOptions?: Record<string, 1 | -1>;
  filters?: Record<string, string[]>;
}
