import { Injectable } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Params, Route, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, withLatestFrom } from 'rxjs/operators';
import { cloneDeep, find as _find, isEqual } from 'lodash';
import { DsBreakpoints } from '@levelaccess/design-system';

import { NavigationItem, NavigationRoutingTree, INavMenuItem, INavMenuItemFilter, INavItemFilterArgs } from './nav-structure';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { UserAclService } from '../user-acl.service';
import { FeatureFlagService } from '../feature-flag/feature-flag.service';
import { AclSecurityAdapter } from '../acl.service';
import { AngularUtility } from '../../utility/angular.utility';
import { Api } from '../../../../shared/constants/api';
import { AppConfigService } from '../app-config.service';
import { WindowService } from '../window.service';
import { TenantPackageService } from '../tenant-package.service';
import { UserService } from '../user.service';
import { IUserServerResponse } from '../../../../shared/interfaces/user.interface';
import { $user } from '../../../../shared/constants/user';
import { ILinkedPropertyData, LinkedPropertyUtility } from '../../../../shared/utils/linked-property.utility';
import { UserDigitalPropertyService } from '../user-digital-property.service';
import { SecurityEntityLevel } from '../../../../shared/constants/security-group';
import { IRequiredSecurity } from '../../../../shared/interfaces/security.interface';

type InternalMenusObservable = [
  user: IUserServerResponse,
  params: Params,
  isWebDp: boolean,
  isMobileDp: boolean,
  adapter: AclSecurityAdapter,
  isMobileView: boolean,
  featureFlagsChanged: void,
];

@Injectable({
  providedIn: 'root',
})
export class NavService {
  private lastActivatedRoute$: Observable<ActivatedRoute>;
  private topLevelMenuItems$: Observable<INavMenuItem[]>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userAclService: UserAclService,
    private featureFlagService: FeatureFlagService,
    private appConfigService: AppConfigService,
    private windowService: WindowService,
    private userService: UserService,
    private tenantPackageService: TenantPackageService,
    private userDigitalPropertyService: UserDigitalPropertyService,
  ) {
    this.createInternalMenusObservable();
    this.subscribeToRouteChanges();
  }

  private createInternalMenusObservable(): void {
    this.topLevelMenuItems$ = combineLatest([
      this.userService.userDataChanged$,
      this.activatedRoute.queryParams,
      this.userDigitalPropertyService.isWebDigitalProperty$,
      this.userDigitalPropertyService.isMobileDigitalProperty$,
      this.userAclService.createCheckAccessForCurrentUser(),
      this.windowService.observeBreakpoint(DsBreakpoints.Medium),
      this.featureFlagService.flagsChange$,
    ]).pipe(
      map(([user, params, isWebDp, isMobileDp, adapter, isMobileView, _]: InternalMenusObservable): INavMenuItem[] => {
        const setAvailableStateAndParent = (item: INavMenuItem, parent?: INavMenuItem): INavMenuItem => {
          const mappedItem: INavMenuItem = {
            ...item,
            isAvailable: true,
            children: [],
            parent: SharedCommonUtility.notNullishOrEmpty(parent) ? parent : undefined,
          };

          if (mappedItem.tenantlessOnly) {
            mappedItem.isAvailable = !this.appConfigService.isTenantEnabled();
          }

          if (mappedItem.isAvailable && mappedItem.tenantOnly) {
            mappedItem.isAvailable = this.appConfigService.isTenantEnabled();
          }

          if (mappedItem.isAvailable && SharedCommonUtility.notNullishOrEmpty(mappedItem.featureFlag)) {
            const negated: boolean = mappedItem.featureFlag[0] === '!';
            const featureFlag: string = negated ? mappedItem.featureFlag.slice(1) : mappedItem.featureFlag;
            const value: boolean = this.featureFlagService.variation(featureFlag, true);

            mappedItem.isAvailable = negated ? !value : value;
          }

          if (mappedItem.isAvailable && SharedCommonUtility.notNullish(item.requiredSecurity)) {
            const isAdmin: boolean = !this.appConfigService.isTenantEnabled();

            mappedItem.isAvailable = adapter
              .useFunctionalActions(mappedItem.requiredSecurity.functionalActions)
              .useOptions({ requireFunctionalActions: true })
              .useRequiredSecurity(mappedItem.requiredSecurity, isAdmin)
              .check();
            adapter.reset();
          }

          if (mappedItem.isAvailable && !isMobileView && mappedItem.mobileOnly) {
            mappedItem.isAvailable = false;
          }

          if (mappedItem.isAvailable && SharedCommonUtility.notNullishOrEmpty(item.children)) {
            mappedItem.children = item.children.map(
              (child: INavMenuItem): INavMenuItem => setAvailableStateAndParent(child, mappedItem),
            );
            const firstAvailableChild: INavMenuItem = mappedItem.children.find(
              (child: INavMenuItem): boolean =>
                child.isAvailable && (Boolean(child.routerLink) || Boolean(child.externalLink) || Boolean(child.externalLinkFn)),
            );
            const hasOverrideLink: boolean =
              Boolean(mappedItem.overrideRouterLink) ??
              mappedItem.children.some(
                (child: INavMenuItem): boolean => child.isAvailable && child.routerLink === mappedItem.overrideRouterLink,
              );

            if (SharedCommonUtility.notNullish(firstAvailableChild)) {
              mappedItem.isAvailable = true;

              // if the nav item has children, points its routerLink to the routerLink of its first available child by default
              mappedItem.routerLink = hasOverrideLink ? mappedItem.overrideRouterLink : firstAvailableChild.routerLink;
            } else {
              mappedItem.isAvailable = SharedCommonUtility.notNullish(mappedItem.routerLink);
            }
          }

          if (mappedItem.isAvailable && SharedCommonUtility.notNullish(item.isAvailableFn)) {
            mappedItem.isAvailable = item.isAvailableFn({ user, packageName: this.tenantPackageService.tenantPackage });
          }

          if (mappedItem.isAvailable && item.restrictByWorkspace) {
            const linkedPropertyData: ILinkedPropertyData = LinkedPropertyUtility.fromLinkedPropertyQueryParam(params);
            mappedItem.isAvailable = user?.[$user.workspacesIds]?.includes(linkedPropertyData[Api.workspaceId]);
          }

          if (mappedItem.isAvailable && SharedCommonUtility.notNullishOrEmpty(mappedItem.filters)) {
            const filterArgs: INavItemFilterArgs = {
              isWebDp,
              isMobileDp,
              appConfigService: this.appConfigService,
            };
            mappedItem.isAvailable = mappedItem.filters.every((navMenuItemFilter: INavMenuItemFilter) => {
              return navMenuItemFilter(filterArgs);
            });
          }

          return mappedItem;
        };
        return this.navigationRoutingTree.map((item: INavMenuItem): INavMenuItem => setAvailableStateAndParent(item));
      }),
      AngularUtility.shareRef(),
    );
  }

  private subscribeToRouteChanges(): void {
    const onlyWhenNavigationEnds = (event: Event): event is NavigationEnd => {
      return event instanceof NavigationEnd;
    };

    const getRoute = (value: [ActivatedRoute, INavMenuItem[]], _: number): ActivatedRoute => {
      let _route: ActivatedRoute = value[0];
      const topLevelMenuItems: INavMenuItem[] = value[1];

      const getRouteMenuItem = (menuItems: INavMenuItem[], route: string): INavMenuItem => {
        return menuItems.reduce((found: INavMenuItem, item: INavMenuItem): INavMenuItem => {
          if (found) {
            return found;
          }
          if (item.children && item.children.length > 0) {
            return getRouteMenuItem(item.children, route);
          }
          return item.routerLink === route ? item : null;
        }, null);
      };

      while (_route.firstChild) {
        const path: string = this.getFullPathFromActivatedRoute(_route);
        if (SharedCommonUtility.isNullish(path) || SharedCommonUtility.isNullishOrEmpty(_route.pathFromRoot)) {
          _route = _route.firstChild;
          continue;
        }

        const routeMenuItem: INavMenuItem = getRouteMenuItem(topLevelMenuItems, path);
        if (SharedCommonUtility.notNullish(routeMenuItem) && !routeMenuItem.isAvailable) {
          const firstAvailableRoute: INavMenuItem = routeMenuItem.parent.children.find(
            (child: INavMenuItem): boolean => child.isAvailable,
          );
          if (SharedCommonUtility.notNullish(firstAvailableRoute)) {
            this.router.navigate([firstAvailableRoute.routerLink]).then();
          }
        }
        _route = _route.firstChild;
      }
      return _route;
    };

    this.lastActivatedRoute$ = this.router.events.pipe(
      filter(onlyWhenNavigationEnds),
      map((): ActivatedRoute => this.activatedRoute),
      withLatestFrom(this.topLevelMenuItems$),
      map(getRoute),
      startWith(this.activatedRoute),
      AngularUtility.shareRef(),
    );
  }

  private getFullPathFromActivatedRoute(route: ActivatedRoute): string {
    return (
      '/' +
      route?.pathFromRoot
        ?.filter((v: ActivatedRoute): Route => v.routeConfig)
        .map((v: ActivatedRoute): string => v.routeConfig.path)
        .join('/')
    );
  }

  private getAvailableSubmenusForItem(triggerItem: NavigationItem, selectedItem: INavMenuItem): INavMenuItem[] {
    const findSubmenuItem = (item: INavMenuItem): INavMenuItem => {
      if (SharedCommonUtility.isNullish(item)) {
        return undefined;
      }
      if (item.id === triggerItem) {
        return item;
      }

      const activeChildren: INavMenuItem[] = item.children?.filter((child: INavMenuItem): boolean => child.isActive) ?? [];
      for (const child of activeChildren) {
        const foundItem: INavMenuItem = findSubmenuItem(child);
        if (SharedCommonUtility.notNullishOrEmpty(foundItem)) {
          return foundItem;
        }
      }
      return undefined;
    };

    const menuBarItem: INavMenuItem = findSubmenuItem(selectedItem);

    return menuBarItem?.children?.filter((child: INavMenuItem): boolean => child.isAvailable) ?? [];
  }

  public getAvailableSubmenuItems$(triggerItem: NavigationItem): Observable<INavMenuItem[]> {
    return this.activeTopLevelMenuItem$().pipe(
      map((selectedItem: INavMenuItem) => this.getAvailableSubmenusForItem(triggerItem, selectedItem)),
    );
  }

  public get navigationRoutingTree(): INavMenuItem[] {
    return NavigationRoutingTree;
  }

  /**
   * Returns the active top level menu item.
   * Is considered active if itself or any of its descendants matches the active route.
   *
   */
  public activeTopLevelMenuItem$(): Observable<INavMenuItem> {
    return combineLatest([this.lastActivatedRoute$, this.topLevelMenuItems$]).pipe(
      map(([_, topLevelMenuItems]: [ActivatedRoute, INavMenuItem[]]): INavMenuItem => {
        const url: string = this.router.routerState.snapshot.url;
        const topLevelMenuItemsMarked: INavMenuItem[] = cloneDeep(topLevelMenuItems);
        const checkIfMenuItemIsActiveAndMarkIt = (menuItems: INavMenuItem[]): void => {
          for (const menuItem of menuItems) {
            if (SharedCommonUtility.notNullishOrEmpty(menuItem.children)) {
              checkIfMenuItemIsActiveAndMarkIt(menuItem.children);
              menuItem.isActive = menuItem.children.some((child: INavMenuItem): boolean => child.isActive);
            } else {
              menuItem.isActive = url.startsWith(menuItem.routerLink) && menuItem.isAvailable;
            }

            // Early stop: there won't be more than one menu item active in the same level
            if (menuItem.isActive) {
              break;
            }
          }
        };

        checkIfMenuItemIsActiveAndMarkIt(topLevelMenuItemsMarked);

        return topLevelMenuItemsMarked.find((topLevelMenuItem: INavMenuItem) => topLevelMenuItem.isActive);
      }),
      AngularUtility.shareRef(),
    );
  }

  public activeSecondLevelMenuItem$(): Observable<INavMenuItem | undefined> {
    const toggleAvailableStateForPortfolio = (secondLevelNavMenuItems: INavMenuItem[]): void => {
      if (SharedCommonUtility.isNullishOrEmpty(secondLevelNavMenuItems)) {
        return;
      }

      const isPortfolioView: boolean = this.router.routerState.snapshot.url.startsWith(`/${Api.portfolio}`);
      const websitesAndAppNavigationItem: INavMenuItem = secondLevelNavMenuItems.find(
        (secondLevelNavMenuItem: INavMenuItem): boolean => secondLevelNavMenuItem.id === NavigationItem.websites_and_apps,
      );

      if (SharedCommonUtility.notNullish(websitesAndAppNavigationItem)) {
        const websitesAndAppsChildren: INavMenuItem[] = websitesAndAppNavigationItem.children ?? [];

        websitesAndAppsChildren.map((websitesAndAppsChild: INavMenuItem): void => {
          const isPortfolio: boolean = isPortfolioView && websitesAndAppsChild.id === NavigationItem.websites_and_apps_portfolio;
          const isNotPortfolio: boolean =
            !isPortfolioView && websitesAndAppsChild.id !== NavigationItem.websites_and_apps_portfolio;
          websitesAndAppsChild.isAvailable = websitesAndAppsChild.isAvailable && (isPortfolio || isNotPortfolio);
        });
      }
    };

    return this.activeTopLevelMenuItem$().pipe(
      map((activeTopLevelMenuItem: INavMenuItem) => {
        const secondLevelNavMenuItems: INavMenuItem[] = activeTopLevelMenuItem?.children ?? [];
        toggleAvailableStateForPortfolio(secondLevelNavMenuItems);
        return secondLevelNavMenuItems.find((secondLevelMenuItem: INavMenuItem) => secondLevelMenuItem.isActive);
      }),
      AngularUtility.shareRef(),
    );
  }

  public activeThirdLevelMenuItem$(): Observable<INavMenuItem | undefined> {
    return this.activeSecondLevelMenuItem$().pipe(
      map((activeSecondLevelMenuItem: INavMenuItem): INavMenuItem => {
        const thirdLevelNavMenuItems: INavMenuItem[] = activeSecondLevelMenuItem?.children ?? [];
        let activeThirdLevelMenuItem: INavMenuItem = thirdLevelNavMenuItems.find(
          (thirdLevelNavMenuItem: INavMenuItem): boolean => thirdLevelNavMenuItem.isActive,
        );
        if (SharedCommonUtility.notNullishOrEmpty(activeThirdLevelMenuItem?.children)) {
          activeThirdLevelMenuItem =
            activeThirdLevelMenuItem.children.find((thirdLevelChild: INavMenuItem): boolean => thirdLevelChild.isActive) ??
            activeThirdLevelMenuItem;
        }
        return activeThirdLevelMenuItem;
      }),
      AngularUtility.shareRef(),
    );
  }

  public activeMenuItemForWorkspaceSwitcher$(): Observable<INavMenuItem | undefined> {
    type IWorkspaceSwitcherProps = [INavMenuItem[], INavMenuItem, INavMenuItem, INavMenuItem];
    return combineLatest([
      this.getTopLevelMenuItems$([NavigationItem.workspace]),
      this.activeTopLevelMenuItem$(),
      this.activeSecondLevelMenuItem$(),
      this.activeThirdLevelMenuItem$(),
    ]).pipe(
      distinctUntilChanged(isEqual),
      map(
        ([
          workspacesMenuItem,
          activeTopLevelMenuItem,
          activeSecondLevelMenuItem,
          activeThirdLevelMenuItem,
        ]: IWorkspaceSwitcherProps): INavMenuItem => {
          if (activeTopLevelMenuItem?.id !== NavigationItem.workspace) {
            return workspacesMenuItem?.[0];
          } else if (
            !SharedCommonUtility.isStringEmpty(activeThirdLevelMenuItem?.routerLink) &&
            activeThirdLevelMenuItem?.isAvailable
          ) {
            return activeThirdLevelMenuItem;
          } else if (
            !SharedCommonUtility.isStringEmpty(activeSecondLevelMenuItem?.routerLink) &&
            activeSecondLevelMenuItem?.isAvailable
          ) {
            return activeSecondLevelMenuItem;
          } else if (
            !SharedCommonUtility.isStringEmpty(activeTopLevelMenuItem?.routerLink) &&
            activeTopLevelMenuItem?.isAvailable
          ) {
            return activeTopLevelMenuItem;
          }

          return workspacesMenuItem?.[0];
        },
      ),
      AngularUtility.shareRef(),
    );
  }

  public hasAccessToMenuItemInWorkspace$(
    menuItem: INavMenuItem,
    workspaceId: string,
    digitalPropertyId: string,
  ): Observable<boolean> {
    return this.userAclService.createCheckAccessForCurrentUser().pipe(
      map((adapter: AclSecurityAdapter): boolean => {
        if (SharedCommonUtility.isNullish(menuItem?.requiredSecurity)) {
          return true;
        }

        const requiredSecurity: IRequiredSecurity = menuItem.requiredSecurity;
        const isAdmin: boolean = !this.appConfigService.isTenantEnabled();

        if (requiredSecurity.entityLevel === SecurityEntityLevel.workspace) {
          return adapter
            .useRequiredSecurity(requiredSecurity, isAdmin)
            .useFunctionalActions(requiredSecurity.functionalActions)
            .useWorkspaceId(workspaceId)
            .check();
        } else if (requiredSecurity.entityLevel === SecurityEntityLevel.digitalProperty) {
          return adapter
            .useRequiredSecurity(requiredSecurity, isAdmin)
            .useFunctionalActions(requiredSecurity.functionalActions)
            .useWorkspaceId(workspaceId)
            .useDigitalPropertyId(digitalPropertyId)
            .check();
        }

        return true;
      }),
      AngularUtility.shareRef(),
    );
  }

  public getTopLevelMenuItems$(ids: NavigationItem[] = []): Observable<INavMenuItem[]> {
    const filterMenuItemsByIds = (menuItem: INavMenuItem): boolean =>
      SharedCommonUtility.isNullishOrEmpty(ids) || ids.includes(menuItem.id);

    return this.topLevelMenuItems$.pipe(
      map((topLevelMenuItems: INavMenuItem[]): INavMenuItem[] => topLevelMenuItems.filter(filterMenuItemsByIds)),
    );
  }

  /**
   * Determines if the active subtree in the navigation tree has any of the items in the given navItemsIdsToCheck
   * list.
   *
   * @param navItemsIdsToCheck Ids of the navigation items to check in the active subtree of the navigation tree.
   */
  public getNavItemInTheActiveSubtree$(navItemsIdsToCheck: NavigationItem[]): Observable<INavMenuItem | null> {
    const navItemsIdsToCheckSet: Set<NavigationItem> = new Set<NavigationItem>(navItemsIdsToCheck);

    const findIfNavItemIsInTheActiveSubtree = (activeNavItem: INavMenuItem): INavMenuItem | null => {
      if (navItemsIdsToCheckSet.has(activeNavItem.id)) {
        return activeNavItem;
      }

      const activeChild: INavMenuItem = _find(activeNavItem.children ?? [], 'isActive');
      if (SharedCommonUtility.notNullish(activeChild)) {
        return findIfNavItemIsInTheActiveSubtree(activeChild);
      }

      return null;
    };

    return this.activeTopLevelMenuItem$().pipe(
      map(
        (activeTopLevelMenuItem?: INavMenuItem): INavMenuItem =>
          SharedCommonUtility.notNullish(activeTopLevelMenuItem)
            ? findIfNavItemIsInTheActiveSubtree(activeTopLevelMenuItem)
            : null,
      ),
    );
  }
}
